import './assets/styles/index.css'

import { lazy, Suspense, useEffect, useState } from 'react'
import KcAppBase, { useDownloadTerms } from 'keycloakify/login'
import { KcContext, useI18n } from './keycloak-theme/login'
import tos_en from './keycloak-theme/login/assets/tos_en.md'

const Template = lazy(() => import('keycloakify/login/Template'))

export type Props = {
  kcContext: KcContext
}

export default function App(props: Props) {
  const [theme, setTheme] = useState(getSystemTheme())

  useEffect(() => {
    const updateTheme = () => {
      setTheme(getSystemTheme())
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme)

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', updateTheme)
    }
  }, [])

  const { kcContext } = props

  useDownloadTerms({
    kcContext,
    downloadTermMarkdown: async ({ currentLanguageTag }) => {
      return await fetch(tos_en).then((response) => response.text())
    },
  })

  const i18n = useI18n({ kcContext })

  // Text resources for the current language are still being downloaded, we can't display anything yet.
  // We could display a loading progress but it's usually a matter of milliseconds.
  if (i18n === null) {
    return null
  }

  return (
    <Suspense>
      <KcAppBase
        Template={Template}
        kcContext={kcContext}
        i18n={i18n}
        classes={{
          kcHtmlClass: theme,
          kcButtonClass: 'button',
          kcButtonPrimaryClass: 'button--primary',
          kcButtonDefaultClass: 'button--default',
          kcInputClass: 'input',
          kcLabelClass: 'input-label',
          // TODO: Add custom class names for keycloakify elements (it will make it easier to override styles)
        }}
        doUseDefaultCss={false}
      />
    </Suspense>
  )
}

function getSystemTheme() {
  return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
}
