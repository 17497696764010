import { createUseI18n } from 'keycloakify/login'

// Override the default i18n messages or custom ones defined in the Keycloak admin UI for UserProfile (https://user-images.githubusercontent.com/6702424/182050652-522b6fe6-8ee5-49df-aca3-dba2d33f24a5.png)
export const { useI18n } = createUseI18n({
  en: {
    doForgotPassword: 'I forgot my password',
  },
  fr: {
    doForgotPassword: "J'ai oublié mon mot de passe",
  },
})

export type I18n = NonNullable<ReturnType<typeof useI18n>>
